import * as React from 'react';

import { usePageWrapper } from 'hooks/usePageWrapper';
import { SEOHelmet } from 'components/SEO/SEOHelmet';

import NotFoundHero from 'components/Heros/NotFoundHero/NotFoundHero';

const NotFound: React.FC<{}> = () => {
  const [PageWrapperComponent, animatePageContentIn] = usePageWrapper(true);

  return PageWrapperComponent(
    <React.Fragment>
      <SEOHelmet
        schema="WebPage"
        title="Well this is embarassing"
        description="Missing page"
        contentType="product"
      />
      <NotFoundHero animationReady={animatePageContentIn} />
    </React.Fragment>,
  );
};

export default NotFound;
