import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { SEOHelmet } from 'components/SEO/SEOHelmet';

import classNames from 'classnames';

import Footer from 'components/Footer/Footer/Footer';
import UseDesktopHero from 'components/Heros/UseDesktopHero/UseDesktopHero';

import * as fromPageScroll from 'store/PageScroll';
import * as fromUserInterface from 'store/UserInterface';

import { useSpring, a, SpringConfig } from 'react-spring';
import { defaultSpringConfig } from 'components/Animations/SpringProperties/SpringProperties';

import { usePrevious, hashCode } from 'utils/Index';

export const usePageWrapper = (fullScreen = false) => {
  const history = useHistory();
  const location = useLocation();

  const pageScrollEnabled = useSelector(fromPageScroll.getEnabled);
  const pageScrollTop = useSelector(fromPageScroll.getTop);

  const selectLoadingScreen = React.useMemo(
    fromUserInterface.makeGetLoadingScreen,
    [],
  );
  const [screenId] = React.useState(hashCode(location.pathname).toString());

  const loadingScreen = useSelector(state =>
    selectLoadingScreen(state, screenId),
  );

  const dispatch = useDispatch();
  const changeLoadingScreenLogomarkBackground = React.useCallback(
    (url: string) => dispatch(fromUserInterface.actionCreators.changeLoadingScreenLogomarkBackground(screenId, url))
    , [dispatch]);

  const previousAnimatePageIn = usePrevious(loadingScreen.animatePageIn);

  React.useEffect(() => {
    if (loadingScreen.animatePageIn && !previousAnimatePageIn) {
      const action = history.action;
      console.log(action);

      if (action === 'PUSH' || action === 'POP' || action === 'REPLACE') {
        window.scrollTo(0, 0);
      }
    }
  }, [loadingScreen.animatePageIn, history]);

  React.useEffect(() => {
    console.log('rerendering the usePageWrapper hook');
  });

  const footerComponent = () => {
    if (!fullScreen)
      return <Footer animationReady={loadingScreen.animatePageContentIn} />;
  };

  const opacitySpringConfig: SpringConfig = {
    ...defaultSpringConfig,
    tension: 85,
    friction: 29,
  };
  const topSpringConfig: SpringConfig = {
    ...opacitySpringConfig,
    tension: 155,
    friction: 32,
  };
  const [animateInOpacitySpring, setAnimateInOpacitySpring] = useSpring(() => ({ opacity: 0, config: opacitySpringConfig }));
  const [animateInTopSpring, setAnimateInTopSpring] = useSpring(() => ({ top: 400, config: topSpringConfig }));

  React.useEffect(() => {
    setAnimateInOpacitySpring({ opacity: loadingScreen.animatePageIn ? 1 : 0 });
    setAnimateInTopSpring({ top: loadingScreen.animatePageIn ? 0 : 400 });
  }, [loadingScreen.animatePageIn]);

  //const [pageOffsetSpring, setPageOffsetSpring] = useSpring(() => ({ y: 0, config: topSpringConfig, immediate: true }));

  //React.useEffect(() => {
  //  setPageOffsetSpring({ y: !pageScrollEnabled ? pageScrollTop : 0 });
  //}, [pageScrollEnabled, pageScrollTop]);

  const pageWrapperStyles = {
    opacity: animateInOpacitySpring.opacity,
    //paddingTop: animateInTopSpring.top.to(x => `${x}px`),
    //transform: animateInTopSpring.top.to(x => x !== 0 ? `translateY(${x}px)` : null),
    //paddingBottom: pageOffset + 'px',
    //marginTop: -pageOffset + 'px',
    //paddingBottom: pageOffsetSpring.y.to(y => `${y}px`),
    //marginTop: pageOffsetSpring.y.to(y => `-${y}px`),
    paddingBottom: !pageScrollEnabled ? `${pageScrollTop}px` : `0px`,
    marginTop: !pageScrollEnabled ? `-${pageScrollTop}px` : `0px`,
  };

  const PageWrapperComponent = (children: JSX.Element) => (
    <a.div className='pageContainer' style={pageWrapperStyles}>
      <div
        className={classNames(
          'pageWrapper',
          fullScreen,
          'mobile',
        )}
      >
        <SEOHelmet
          schema="WebPage"
          title="Our mobile site should really be finished"
          description="Our mobile site should really be finished"
          contentType="product"
        />
        <UseDesktopHero animationReady={loadingScreen.animatePageContentIn} />
      </div>
      <div
        className={classNames(
          'pageWrapper',
          { fullScreen },
          'desktop',
        )}
      >
        {/* render the wrapped component like this, passing the props and state */}
        {children}
        {/*{(!this.props.pageScrollEnabled ? <ScrollLock accountForScrollbars={true} /> : null)}*/}

        {footerComponent()}


      </div>
    </a.div>
  );

  let returnValues: [(children: JSX.Element) => JSX.Element, boolean, (url: string) => void, boolean];
  returnValues = [PageWrapperComponent, loadingScreen.animatePageContentIn, changeLoadingScreenLogomarkBackground, loadingScreen.animatePageIn];

  return returnValues;
};
